const SERVERLESS_FN_URL = "https://workers-airtable-form.the-crafty-montessorian.workers.dev/submit"

export default () => (
  <form action={SERVERLESS_FN_URL} method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
    <div>
      <label htmlFor="name" className="block text-sm font-medium text-warm-gray-900">
        Full Name
      </label>
      <div className="mt-1">
        <input
          type="text"
          name="name"
          id="name"
          autoComplete="name"
          placeholder="Full Name"
          required
          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
        />
      </div>
    </div>
    <div>
      <div className="flex justify-between">
        <label htmlFor="email" className="block text-sm font-medium text-warm-gray-900">
          Email
        </label>
      </div>
      <div className="mt-1">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          placeholder="person@example.com"
          required
          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
        />
      </div>
    </div>
    <div>
      <div className="flex justify-between">
        <label htmlFor="phone" className="block text-sm font-medium text-warm-gray-900">
          Phone
        </label>
      </div>
      <div className="mt-1">
        <input
          type="text"
          name="phone"
          id="phone"
          autoComplete="tel"
          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
          placeholder="+1 (123) 456-7890"
          required
        />
      </div>
    </div>
    <div className="sm:col-span-2">
      <div className="flex justify-between">
        <label htmlFor="children" className="block text-sm font-medium text-warm-gray-900">
          Names of children and ages
        </label>
      </div>
      <div className="mt-1">
        <textarea
          id="children"
          name="children"
          rows={4}
          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
          required
          defaultValue={''}
        />
      </div>
    </div>
    <div className="sm:col-span-2">
      <div className="flex justify-between">
        <label htmlFor="goals" className="block text-sm font-medium text-warm-gray-900">
          What are your top three goals for this course?
        </label>
      </div>
      <div className="mt-1">
        <textarea
          id="goals"
          name="goals"
          rows={4}
          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
          required
          defaultValue={''}
        />
      </div>
    </div>
    <div className="sm:col-span-2">
      <div className="flex justify-between">
        <label htmlFor="experience" className="block text-sm font-medium text-warm-gray-900">
          Do you have any experience with the Montessori Method?
        </label>
      </div>
      <div className="mt-1">
        <textarea
          id="experience"
          name="experience"
          rows={4}
          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
          required
          defaultValue={''}
        />
      </div>
    </div>
    <div className="sm:col-span-2">
      <div className="mt-1">
        <input
          type="checkbox"
          name="newsletterOptIn"
          id="newsletterOptIn"
          // className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
          required
        />
        <label htmlFor="newsletterOptIn" className="block text-sm font-medium text-warm-gray-900">
          I understand that by signing up for this course I am also signing up for the BraveCare newsletter
        </label>
      </div>
    </div>
    <div className="sm:col-span-2 sm:flex sm:justify-end">
      <button
        type="submit"
        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
      >
        Submit
      </button>
    </div>
  </form >
)
